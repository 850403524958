<template>
  <div class="diadin">
    <div class="display" style="align-items: flex-start">
      <div style="width: 300px; height: 300px; background-color: #f2f2f2">
        图片
      </div>
      <div style="width: 100%">
        <div class="display">
          <div>
            <div style="color: #013bfe">
              <span
                style="
                  background-color: #e5f2fd;
                  padding: 2px 5px;
                  margin-right: 5px;
                "
                >标题</span
              >
              <span style="font-size: 18px">名字</span>
            </div>
            <div style="margin: 15px">
              <span
                style="
                  background-color: #f2f3f5;
                  padding: 2px 5px;
                  margin-right: 5px;
                  color: #8ca4bb;
                "
                >子类目显示</span
              >
              <span>发布时间</span>
              <span>好评率</span>
            </div>
          </div>
        </div>
        <div style="display: flex; align-items: center">
          商品基础数据
          <div style="width: 200px; margin: 0 10px 0 30px">
            <el-date-picker
              v-model="data1"
              type="daterange"
              align="right"
              :clearable="false"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
          <el-radio-group v-model="radio2" size="medium">
            <el-radio-button label="7日"></el-radio-button>
            <el-radio-button label="15日"></el-radio-button>
            <el-radio-button label="30日"></el-radio-button>
            <el-radio-button label="90日"></el-radio-button>
            <el-radio-button label="180日"></el-radio-button>
            <el-radio-button label="365日"></el-radio-button>
            <el-radio-button label="730日"></el-radio-button>
            <el-radio-button label="节促"></el-radio-button>
          </el-radio-group>
        </div>
        <div style="display: flex">
          <div>
            <div>最新到手价</div>
            <div>$2.24-$4.94</div>
            <div>￥16.06-￥35.41</div>
          </div>
          <div>
            <div>历史最低价</div>
            <div>1111</div>
          </div>
          <div>
            <div>评分</div>
            <div>4.7</div>
          </div>
          <div>
            <div>评论数</div>
            <div>8w</div>
          </div>
          <div>
            <div>近30日销量</div>
            <div>10w-30w</div>
          </div>
          <div>
            <div>供货价</div>
            <div>￥0.01-0.31</div>
          </div>
        </div>
      </div>
      <div style="display: flex">
        <div
          style="
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 10px;
          "
        ></div>
        <div
          style="
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 10px;
            margin-left: 10px;
          "
        ></div>
        <div
          style="
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 10px;
            margin-left: 10px;
          "
        ></div>
        <div
          style="
            width: 30px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 10px;
            margin-left: 10px;
          "
        ></div>
      </div>
    </div>
    <div class="display" style="font-size: 18px">
      <div style="width: 24%">平台：tema</div>
      <div style="width: 24%">市场：加拿大</div>
      <div style="width: 24%">在售店铺数：10</div>
      <div
        style="
          color: #fe8873;
          border: 1px solid #fe8873;
          border-radius: 5px;
          font-size: 12px;
          padding: 2px 5px;
        "
      >
        店铺热销榜：第一名
      </div>
    </div>
    <div>
      <div>
        <div>
          <el-radio-group v-model="radio2" size="mini">
            <el-radio-button label="销量"></el-radio-button>
            <el-radio-button label="销售数"></el-radio-button>
            <el-radio-button label="价格"></el-radio-button>
            <el-radio-button label="评价数"></el-radio-button>
            <el-radio-button label="在售店铺数：10"></el-radio-button>
          </el-radio-group>
          <el-switch
            style="margin-left: 50px"
            v-model="value1"
            active-text="多指标分析"
            inactive-text=""
          >
          </el-switch>
        </div>
        <div>
          <tendency></tendency>
        </div>
      </div>
    </div>
    <div style="font-size: 19px">店铺详情</div>
    <div class="display" style="align-items: flex-start">
      <div
        style="
          width: 100px;
          height: 100px;
          background-color: #f2f2f2;
          margin-right: 20px;
        "
      >
        图片
      </div>
      <div>
        <div style="font-size: 18px">juma</div>
        <div style="margin: 10px">
          主营类目：
          <span
            style="
              display: inline-block;
              background-color: #f2f2f2;
              padding: 2px 5px;
            "
            >服装</span
          >
        </div>
        <div
          style="
            width: 150px;
            height: 40px;
            background-color: #0052d9;
            color: #fff;
            text-align: center;
            line-height: 40px;
            border-radius: 5px;
          "
        >
          店铺链接
        </div>
      </div>
    </div>
    <div>
        <el-descriptions title="" direction="vertical" :column="4" border>
  <el-descriptions-item label="开店时间" :span="1">kooriookami</el-descriptions-item>
  <el-descriptions-item label="商品数" :span="1">18100000000</el-descriptions-item>
  <el-descriptions-item label="总销量" :span="1">苏州市</el-descriptions-item>
  <el-descriptions-item label="粉丝数" :span="1">
    苏州市
  </el-descriptions-item>
  <el-descriptions-item label="总销售额">江苏省苏州市吴</el-descriptions-item>
  <el-descriptions-item label="评分">18100000000</el-descriptions-item>
  <el-descriptions-item label="评论数">18100000000</el-descriptions-item>
  <el-descriptions-item label="平均客单价">18100000000</el-descriptions-item>
</el-descriptions>
    </div>
  </div>
</template>
    
    <script>
import {  } from "../../api/api";
import tendency from "../../components/datazx";
export default {
  components: {
    tendency,
  },
  data() {
    return {
      curPage: "1",
      pageSize: 10,
      totale: "",
      data1: "",
      radio2: "",
      tableData: [
        {
          title: "盘点计划名称",
          num: "任务数量",
          complete: "完成度",
          createdTime: "创建时间",
          endTime: "计划完成",
          status: "状态",
          id: "id",
        },
      ],
    };
  },
  created() {
    // this.biao();
  },
  methods: {
    async biao() {
      let param = new URLSearchParams();
      param.append("curPage", this.curPage);
      param.append("pageSize", this.pageSize);
      param.append("planName", this.formInline.name);
      param.append("status", this.formInline.status);
      const { data: res } = await producthome(param);

      this.tableData = res.data;

      this.totale = res.data.total;
      this.curPage = res.data.page;
      this.pagesize = res.data.size;
    },
  },
};
</script>
    
    <style lang="less" scoped>
.diadin {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 12px;
}
.display {
  display: flex;

  padding: 20px;
  align-items: center;
}
.border {
  border-bottom: 1px solid #eeeeee;
}
.minisize {
  font-style: 14px;
  span {
    margin: 0 8px;
    cursor: pointer;
  }
  .stotusbox {
    color: #d4d4d4;
    font-size: 15px;
  }
}
/deep/.el-select {
  width: 100%;
}
.actoin {
  color: #409eff !important;
}
/deep/.el-form-item__content {
  width: 82%;
}
/deep/.el-input-group__append {
  width: 40%;
}
/deep/.el-input__inner {
  background-color: #f5f7fa !important;
  height: 35px !important;
  width: 100%;
}
/deep/.el-input__icon {
  line-height: 35px !important;
}
.titlable {
  width: 130px;
  min-width: 100px;
  text-align: right;
  padding-right: 10px;
}
</style>